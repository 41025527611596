<template>
  <div>
    <div class="cebox">
      <ul>
        <li @click="GoToFoot"><i class="el-icon-phone-outline"></i>电话</li>
        <li @click="GoToFoot"><i class="el-icon-map-location"></i>地址</li>
      </ul>
    </div>
    <!-- 轮播图 -->
    <!-- <el-carousel arrow="always" height="550px">
      <el-carousel-item v-for="(item, index) in img" :key="index">
        <img :src="item.image" alt="" style="height: 100%; width: 100%" />
      </el-carousel-item>
    </el-carousel> -->
    <img src="http://system.yxht.net/yaan_2.png" alt="" style="height:705px;width:100%">
    <!-- 核心优势 -->
    <div style="background: #f7f8fb; width: 100%; height: 450px">
      <div style="width: 100px; height: 20px"></div>
      <div class="flex-center font1" style="margin-top: 40px">
        铸造卓越品质专注刹车制动系统
      </div>
      <div class="kernel">
        <div class="hexin box-111">
          <div class="img151">
            <img src="http://system.yxht.net/Winletter_3.1.png" alt="" />
          </div>
          <div>
            <!-- <div class="font71">宗旨</div> -->
            <div class="font61">
              采用高碳合金灰铸铁材料，更好的铸造性、减振性、耐磨性、切削加工性和较低的缺口敏感性
            </div>
          </div>
        </div>
        <div class="hexin box-111">
          <div class="img151">
            <img src="http://system.yxht.net/Winletter_3.2.png" alt="" />
          </div>
          <div>
            <!-- <div class="font71">理念</div> -->
            <div class="font61">
              采用高碳合金HT250或以上材质，使得制动鼓机械强度较高，不易爆裂，不易磨损。
            </div>
          </div>
        </div>
        <div class="hexin box-111">
          <div class="img151">
            <img src="http://system.yxht.net/Winletter_3.3.png" alt="" />
          </div>
          <div>
            <!-- <div class="font71">更人性化的设计</div> -->
            <div class="font61">
              合金材料的加入(如铜、铬、锰、锡等)使得机械性能大增，机械强度、热耐磨性能、抗热疲劳性能等均比未加合金元素的制动鼓强度高40MPa以上。
            </div>
          </div>
        </div>
        <div class="hexin box-111">
          <div class="img151">
            <img src="http://system.yxht.net/Winletter_3.4.png" alt="" />
          </div>
          <div>
            <!-- <div class="font71">拓展性强</div> -->
            <div class="font61">
              全自动壳型生产线，所得制动鼓外观光洁、外表致密、材质均匀、壁厚均匀、性能稳定、使用可靠。
            </div>
          </div>
        </div>
        <!-- <div class="hexin box-111">
          <div class="img151"><img src="../assets/3.5.png" alt="" /></div>
          <div>
            <div class="font71">更利于优化</div>
            <div class="font61">
              根据最新行业变动情况，尤其是在SEO等方面，会考虑在功能/代码等方面进行优化。
            </div>
          </div>
        </div>
        <div class="hexin box-111">
          <div class="img151"><img src="../assets/3.6.png" alt="" /></div>
          <div>
            <div class="font71">转化效果更好</div>
            <div class="font61">
              用户体验提升+用户思维运用+强大的后台功能可以获得更好的订单转化。
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div style="width: 100%; height: 30px; background-color: #f7f8fb"></div>
    <div
      class="promise"
      style="
        padding-bottom: 10px;
        background-color: #f7f8fb;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      我们承诺
    </div>
    <div style="width: 100%; background-color: #f7f8fb">
      <div
        style="
          width: 80%;
          margin: 0px auto;
          flex-wrap: wrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div class="commitment">
          <div
            style="
              width: 100%;
              height: 40%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 40px;
            "
          >
            <div style="border-radius: 2px; background: #ffffff">
              <img
                src="http://system.yxht.net/Winletter_7.1.png"
                alt=""
                style="width: 100px; height: 100px"
              />
            </div>
          </div>
          <div class="font911">7*24小时服务</div>
          <div class="font912">
            我们为顾客提供了7*24小时服务，不论遇到什么问题，我们都会尽快为您解决
          </div>
        </div>

        <div class="commitment">
          <div
            style="
              width: 100%;
              height: 40%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 40px;
            "
          >
            <div style="border-radius: 2px; background: #ffffff">
              <img
                src="http://system.yxht.net/Winletter_7.2.png"
                alt=""
                style="width: 100px; height: 100px"
              />
            </div>
          </div>
          <div class="font911">目标</div>
          <div class="font912">
            致力于成为重卡制动领域的领航者、制动鼓标准的制定专家，厚德人本、精诚合作、创新卓越、无限多赢
          </div>
        </div>

        <div class="commitment">
          <div
            style="
              width: 100%;
              height: 40%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 40px;
            "
          >
            <div style="border-radius: 2px; background: #ffffff">
              <img
                src="http://system.yxht.net/Winletter_7.3.png"
                alt=""
                style="width: 100px; height: 100px"
              />
            </div>
          </div>
          <div class="font911">愿景</div>
          <div class="font912">
            做汽车配件精品、创赢信汇通品牌，诚信立足、共赢发展、汇聚资源、通达天下
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px; background-color: #f7f8fb"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      // img: [
      //   {
      //     image: "http://system.yxht.net/Winletter_1.png",
      //   },
      //   {
      //     image: "http://system.yxht.net/Winletter_2.png",
      //   },
      //   {
      //     image: "http://system.yxht.net/Winletter_3.png",
      //   },
      //   {
      //     image: "http://system.yxht.net/Winletter_4.png",
      //   },
      // ],
      // img: [
      //   {
      //     image: require("../assets/401_images/yxht1.png"),
      //   },
      //   {
      //     image: require("../assets/401_images/yxht2.png"),
      //   },
      //   {
      //     image: require("../assets/401_images/yxht3.png"),
      //   },
      //   {
      //     image: require("../assets/401_images/yxht4.png"),
      //   },
      //   {
      //     image: require("../assets/401_images/yxht5.png"),
      //   },
      // ],
    };
  },
  methods: {
    GoToFoot() {
      window.scrollTo(0, 50000);
    },
  },
  mounted() {},
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
.cebox {
  z-index: 99;
  position: fixed;
  left: 30px;
  top: 400px;
  /* height: 300px; */
}
.cebox ul li {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  box-shadow: 3px 2px 6px #999;
  transition: width 0.3s;
  font-size: 18px;
  font-weight: 700;
  padding-left: 15px;
  overflow: hidden;
  background-color: #fff;
}

.cebox ul li i {
  margin-right: 25px;
  line-height: 60px;
  font-size: 26px;
}
.cebox ul li:hover {
  width: 160px;
  background-color: #0099ff;
  color: #fff;
}
</style>